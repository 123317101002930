<template>
  <div id="login-requests">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" title="LoginRequests">
      <template v-slot:right-end>
        <div class="vx-row">
          <vs-input label="Search" v-model="searchText"/>
          <div class="flex justify-center ml-4" style="place-items: end;">
            <vs-button class="mt-5" @click="searchRequests">{{ $t('Search') }}</vs-button>
          </div>
        </div>
      </template>
    </breadcrumb-base>


    <!-- Error List State-->
    <transition name="fade">
      <div class="py-6 flex w-full bg-img" v-if="errorFetching">
        <div
          class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
          <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
               class="mx-auto mb-4 max-w-full">
          <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('ErrorData')}}</h1>
        </div>
      </div>
    </transition>

    <transition name="fade">

      <div class="vx-row">
        <div class="w-full">
          <!-- Empty List State -->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="loginRequests.length === 0 && !errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
<!--                <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
<!--                     class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
              </div>
            </div>
          </transition>
          <vs-table-modified v-show="loginRequests.length > 0 && !errorFetching" ref="table"
                             :max-items="itemsPerPage"
                             :data="loginRequests">
<!--            "id": 655,-->
<!--            "reference_id": "af82d",-->
<!--            "auth_status": 1,-->
<!--            "created": "2020-11-09T11:28:03.730290Z",-->
<!--            "updated": "2020-11-09T16:48:40.167874Z",-->
<!--            "first_name": "chandu",-->
<!--            "last_name": "arepalli",-->
<!--            "customer_id": 500000,-->
<!--            "organisation_name": "PJ TeleSoft",-->
<!--            "username": "chanduarepalli1234"-->
            <template slot="thead">
              <vs-th >{{$t('Reference')}}</vs-th>
              <vs-th >{{$t('UserName')}}</vs-th>
              <vs-th >{{$t('FullName')}}</vs-th>
<!--              <vs-th >{{$t('CustomerId')}}</vs-th>-->
              <vs-th >{{$t('Organisation')}}</vs-th>
              <vs-th >{{$t('AuthStatus')}}</vs-th>
              <vs-th >{{$t('RequestedAt')}}</vs-th>
              <vs-th >{{$t('ApprovedBy')}}</vs-th>
              <vs-th >{{$t('ApprovedAt')}}</vs-th>
              <vs-th>{{ $t('Action') }}</vs-th>
            </template>
            <template slot-scope="{data}">
              <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.reference_id }}</p>
                </vs-td>

                <vs-td>
                  <vx-tooltip>
                    <template slot="tip">
                      <p><strong>Mobile: {{ tr.mobile }}</strong></p>
                      <p><strong>Mobile1: {{ tr.mobile_1 }}</strong></p>
                      <p><strong>Mobile2: {{ tr.mobile_2 }}</strong></p>
                    </template>
                    <div class="vs-col">
                      <p class="user-name font-medium truncate">{{ tr.username }}</p>
                      <p class=" truncate" style="font-size: 12px">{{ tr.customer_id }}</p>
                    </div>
                  </vx-tooltip>
                </vs-td>

                <vs-td>
                  <p class="user-name font-medium truncate" style="max-width: 150px;">{{ tr.first_name }} {{ tr.last_name }}</p>
                </vs-td>

<!--                <vs-td>-->
<!--                  <p class="user-name font-medium truncate">{{ tr.customer_id }}</p>-->
<!--                </vs-td>-->


                <vs-td>
                  <div class="vs-col">

                    <p class="user-name font-medium truncate">{{ tr.organisation_name }}</p>
<!--                    <p class=" truncate" style="font-size: 12px">{{ tr.street_name }}</p>-->
                    <p style="font-size: 11px;">
                      {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                    </p>

                    <p style="font-size: 11px;">
                      {{ tr.postal }} {{ tr.city }}
                    </p>
                  </div>
                </vs-td>

                <vs-td>
                  <div class="text-center flex justify-center">
                    <vs-chip :color="getStatusColor(tr.auth_status)" class="user-status">{{
                        getStatusText(tr.auth_status) | title }}
                    </vs-chip>
                  </div>
                </vs-td>


                <vs-td>
                  <p class="user-name font-medium truncate"
                     style="max-width: 100px;white-space: normal !important;padding: 0 8px;"
                  >{{ tr.created | dateFormat }}</p>
                </vs-td>


                <vs-td>
                  <p class="user-name font-medium truncate">{{ tr.approved_user }}</p>
                </vs-td>

                <vs-td>
                  <p class="user-name font-medium truncate"
                     style="max-width: 100px;white-space: normal !important;padding: 0 8px;"
                  >{{ tr.updated | dateFormat }}</p>
                </vs-td>


                <vs-td class="whitespace-no-wrap vx-row">
                  <vs-button v-if="tr.auth_status == 0" class="ml-2" type="border" color="success"
                             @click="popApproveConfirmModal(tr.id)" >{{ $t('Approve') }}</vs-button>
                </vs-td>

              </vs-tr>
              </tbody>
            </template>
            <template slot="footer">
            </template>
            <template slot="footer-left">
              <div class="flex">
                <vs-new-pagination :total="vTotalPages" v-model="cPage" v-on:change="listPageChanged">
                </vs-new-pagination>
              </div>
            </template>
          </vs-table-modified>
        </div>
      </div>
    </transition>
    <vx-modal v-if="showApproveConfirmModal" @close="closeApproveConfirmModal"
              component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-2 flex flex-centered">
          <vs-col>
            <div class="p-2"></div>
            <p>{{ $t('loginRequestApproveModal') }}</p>
            <div class="p-2"></div>

            <div class="vs-row flex justify-center mt-3">
              <vs-button class="ml-2 mt-3" color="primary" @click="approveRequest(selectedRequestId)">{{ $t('Yes') }}</vs-button>
              <vs-button class="ml-4 mt-3" color="danger" @click="closeApproveConfirmModal">{{
                  $t('No')
                }}
              </vs-button>
            </div>
          </vs-col>
        </div>
      </template>
    </vx-modal>
  </div>
</template>

<script>
import VxModal from "@/layouts/components/custom/VxModal";
export default {
  name: 'LoginRequests',
  components: {VxModal},
  data() {
    return {
      cPage: 1,
      itemsPerPage: 20,
      searchText: '',
      errorFetching: false,
      showApproveConfirmModal: false,
      selectedRequestId: null,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Login requests', i18n: 'LoginRequests', active: true },
      ],
    }
  },
  computed: {
    loginRequests() {
      return this.$store.state.loginRequests.loginRequests
    },
    vPage() {
      return this.$store.state.loginRequests.vPage
    },
    vTotalPages() {
      return this.$store.state.loginRequests.vTotalPages
    },
  },
  methods: {
    popApproveConfirmModal(id) {
      this.selectedRequestId = id;
      this.showApproveConfirmModal = true;
    },
    closeApproveConfirmModal() {
      this.showApproveConfirmModal = false
      this.selectedRequestId = null;
    },
    pageChanged(newIndex) {
      if (isNaN(newIndex)) {
        this.selectedAlpha = newIndex
        this.selectedAlphaCurrentX = newIndex
        this.searchText = this.selectedAlphaCurrentX
      }
    },
    getStatusText(status) {
      // New -  New - Red Colour
      // Approved - Admin approved - Yellow Colour
      // Self - Verified OTP - Green
      // Rejected - Rejected - Black
      // 0 new, 1 approved, 2 self and 3 rejected
      if (status === 0) return this.$t('New')
      if (status === 1) return this.$t('Admin approved')
      if (status === 2) return this.$t('Verified OTP')
      if (status === 3) return this.$t('Rejected')
      if (status === 5) return this.$t('disabledLoginOTP')
      if (status === 6) return this.$t('API')
      if (status === 7) return this.$t('Without OTP')
      return this.$t('Rejected')
    },
    getStatusColor(status) {
      if (status === 0) return 'danger'
      if (status === 1) return 'warning'
      if (status === 2) return 'success'
      if (status === 3) return 'black'
      if (status === 5) return 'purple'
      if (status === 7) return 'red'
      return 'black'
    },
    listPageChanged(item) {
      this.fetchLoginRequests(this.cPage - 1)
    },
    approveRequest(id) {
      this.showApproveConfirmModal = false
      const payload = {
        id,
        auth_status: 1,
      }
      this.$vs.loading()
      this.$store.dispatch('loginRequests/approveRequest', { payload })
        .then((data) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          this.fetchLoginRequests()
        })
        .catch((err) => {
          this.$vs.loading.close()
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    searchRequests() {
      this.fetchLoginRequests('0', this.searchText)
    },
    fetchLoginRequests(page = '0', search = '') {
      const payload = {
        'page': page,
        'search_text': search,
      }
      this.$vs.loading()
      this.$store.dispatch('loginRequests/fetchLoginRequests', { payload })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    // this.fetchLoginRequests()
  },
}
</script>

<style scoped lang="scss">
#login-requests {

}
</style>
